import React from "react";
import { Card } from "react-bootstrap";
import "../Cards/card.css";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Cards = ({destination}) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    navigate(`/tour/${destination._id}`);
  };
  return (
    <>
      <div className="img-box">
        <Card onClick={handleCardClick}>
          <Card.Img
            variant="top"
            crossorigin="anonymous"
            src={`http://localhost:8800/public/images/${destination.photo}`}
            className="img-fluid"
            alt={destination.title}
          />
          <Card.Title>

          {destination.title}
          </Card.Title>

          {/* <span className="tours">{destination.tours}</span> */}
        </Card>
      </div>
    </>
  );
};

export default Cards;
