import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../AdvanceSearch/search.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import CustomDropdown from "../CustomDropdown/CustomDropdown";

const AdvanceSearch = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [location, setLocation] = useState("");
  const [maxGroupSize, setMaxGroupSize] = useState("");
  const [locations, setLocations] = useState([]);
  const [groupSizes, setGroupSizes] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await axios.get("http://localhost:8800/api/tours/");
        const tours = response.data;

        const uniqueLocations = [...new Set(tours.map(tour => tour.city))];
        const uniqueGroupSizes = [...new Set(tours.map(tour => tour.maxGroupSize.toString()))];

        setLocations(uniqueLocations);
        setGroupSizes(uniqueGroupSizes);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const selectedLocation = (value) => {
    setLocation(value);
  };

  const selectedGuest = (value) => {
    setMaxGroupSize(value);
  };

  const handleSearch = () => {
    navigate(`/results?city=${encodeURIComponent(location)}&maxGroupSize=${encodeURIComponent(maxGroupSize)}`);
  };

  return (
    <>
      <section className="box-search-advance">
        <Container>
          <Row>
            <Col md={12} xs={12}>
              <div className="box-search shadow-sm">
                <div className="item-search">
                  <CustomDropdown
                    label="Location"
                    onSelect={selectedLocation}
                    options={locations}
                  />
                </div>
                <div className="item-search bd-none">
                  <CustomDropdown
                    label="Group Size"
                    onSelect={selectedGuest}
                    options={groupSizes}
                  />
                </div>
                <div className="item-search bd-none">
                  <Button 
                    className="primaryBtn flex-even d-flex justify-content-center"
                    onClick={handleSearch}
                  >
                    <i className="bi bi-search me-2"></i> Search 
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AdvanceSearch;
