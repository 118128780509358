import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col, Button, Form, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./tourDetails.css";

const TourDetailsPage = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [guests, setGuests] = useState(1);
  const [startDate, setStartDate] = useState(new Date());

  useEffect(() => {
    const fetchTour = async () => {
      try {
        const response = await axios.get(`http://localhost:8800/api/tours/${id}`);
        setTour(response.data);
      } catch (error) {
        console.error("Error fetching tour details:", error);
      }
    };

    fetchTour();
  }, [id]);

  const handleWhatsAppBooking = () => {
    const formattedDate = startDate.toLocaleDateString("en-GB");
    const message = `Hello, I would like to book the trip:
    - Tour: ${tour.title}
    - Destination: ${tour.city}
    - Number of Guests: ${guests}
    - Date: ${formattedDate}
    - Price: $${tour.price}`;
    
    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/+918397073749?text=${encodedMessage}`;
    
    window.location.href = whatsappURL;
  };

  return (
    <Container className="tour-details-container">
      {tour ? (
        <Row className="align-items-center">
          <Col md={6}>
            <img crossorigin="anonymous" src={`http://localhost:8800/public/images/${tour.photo}`} alt={tour.title} className="img-fluid rounded shadow tour-image" />
          </Col>
          <Col md={6}>
            <div className="tour-details-content">
              <h1 className="tour-title">{tour.title}</h1>
              <p className="tour-desc">{tour.desc}</p>
              <p className="tour-info">
                <strong>City:</strong> {tour.city}
              </p>
              <p className="tour-info">
                <strong>Max Group Size:</strong> {tour.maxGroupSize}
              </p>
              {/* <p className="tour-info tour-price">
                <strong>Price:</strong> ${tour.price}
              </p> */}

              <div className="booking-section mt-4 p-3 rounded shadow">
                <Form.Group>
                  <Form.Label>Number of Guests</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max={tour.maxGroupSize}
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mt-3">
                  <Form.Label>Select Your Travel Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <Button
                  variant="success"
                  className="mt-4 w-100 book-btn"
                  onClick={handleWhatsAppBooking}
                >
                  <i className="fab fa-whatsapp"></i> Book via WhatsApp
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <div className="loading-section">
          <Spinner animation="border" variant="primary" />
          <p className="loading-text">Loading tour details...</p>
        </div>
      )}
    </Container>
  );
};

export default TourDetailsPage;
