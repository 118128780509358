import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Header from "./components/Common/Header/Header";
import Footer from "./components/Common/Footer/Footer";
import Contact from "./pages/Contact/Contact";
import Gallery from "./components/Gallery/Gallery";
import ResultsPage from "./pages/TourResult/Result";
import TourDetailsPage from "./pages/TourDetails/TourDetails";
import UploadTourPage from "./pages/Upload/Upload";


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/tour/:id" element={<TourDetailsPage />} />
        <Route path="/pawanhacker5534" element={<UploadTourPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
