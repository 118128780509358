import React, { useState } from 'react';
import axios from 'axios';
import "./contact.css";

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    mobile: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('http://localhost:8800/api/contact', formData);
      // Reset form data after successful submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        message: '',
      });
      // Optionally handle successful submission (e.g., show a success message)
      alert('Message sent successfully!');
    } catch (error) {
      // Optionally handle errors (e.g., show an error message)
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    }
  };

  return (
    <div className="contact_us_2">
      <div className="responsive-container-block big-container">
        <div className="blueBG"></div>
        <div className="responsive-container-block container">
          <form className="form-box" onSubmit={handleSubmit}>
            <div className="container-block form-wrapper">
              <p className="text-blk contactus-head">Get in Touch</p>
              <p className="text-blk contactus-subhead">Nunc erat cursus tellus gravida.</p>
              <div className="responsive-container-block">
                <div className="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6" id="i10mt">
                  <p className="text-blk input-title">FIRST NAME</p>
                  <input
                    className="input"
                    id="ijowk"
                    name="firstName"
                    placeholder="Please enter first name..."
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">LAST NAME</p>
                  <input
                    className="input"
                    id="indfi"
                    name="lastName"
                    placeholder="Please enter last name..."
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">EMAIL</p>
                  <input
                    className="input"
                    id="ipmgh"
                    name="email"
                    placeholder="Please enter email..."
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
                  <p className="text-blk input-title">PHONE NUMBER</p>
                  <input
                    className="input"
                    id="imgis"
                    name="mobile"
                    placeholder="Please enter phone number..."
                    value={formData.mobile}
                    onChange={handleChange}
                  />
                </div>
                <div className="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12" id="i634i">
                  <p className="text-blk input-title">WHAT DO YOU HAVE IN MIND</p>
                  <textarea
                    className="textinput"
                    id="i5vyy"
                    name="message"
                    placeholder="Please enter query..."
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
              <button type="submit" className="submit-btn">Submit</button>
            </div>
            <div className="social-media-links">
              <a href="#" id="ix94i-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-twitter.png" alt="Twitter" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-facebook.png" alt="Facebook" />
              </a>
              <a href="#">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-google.png" alt="Google" />
              </a>
              <a href="#" id="izldf-2">
                <img className="link-img" src="https://workik-widget-assets.s3.amazonaws.com/Footer1-83/v1/images/Icon-instagram.png" alt="Instagram" />
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Contact;
