import React from 'react';
import './about.css';

const AboutUs = () => {
  return (
    <div className="aboutPage_container">
      <div className="aboutPage_banner">
        <h1 className="aboutPage_title">About VOYAPRO JOURNEYS</h1>
        <p className="aboutPage_subtitle">Your Gateway to Unforgettable Adventures</p>
      </div>

      <div className="aboutPage_content">
        <div className="aboutPage_section">
          <h2 className="aboutPage_heading">Who We Are</h2>
          <p className="aboutPage_paragraph">
            At VOYAPRO JOURNEYS, we are passionate about making travel dreams come true. Our expert team
            specializes in curating personalized travel experiences that cater to every desire and destination.
            Whether you seek adventure, relaxation, or romance, we are here to craft the perfect journey for you.
          </p>
        </div>

        <div className="aboutPage_section">
          <h2 className="aboutPage_heading">Our Mission</h2>
          <p className="aboutPage_paragraph">
            Our mission is to provide exceptional travel experiences that leave lasting memories. We believe in
            creating personalized journeys that reflect our clients' individual preferences, all while ensuring
            a seamless and enjoyable travel experience from start to finish.
          </p>
        </div>

        <div className="aboutPage_section">
          <h2 className="aboutPage_heading">Why Choose Us?</h2>
          <ul className="aboutPage_list">
            <li className="aboutPage_listItem">Tailored itineraries to suit your interests and budget</li>
            <li className="aboutPage_listItem">Expert advice and local insights from our travel specialists</li>
            <li className="aboutPage_listItem">24/7 customer support for a worry-free experience</li>
            <li className="aboutPage_listItem">Exclusive partnerships with trusted global travel providers</li>
          </ul>
        </div>

        <div className="aboutPage_section aboutPage_section--team">
          <h2 className="aboutPage_heading">Meet Our Team</h2>
          <p className="aboutPage_paragraph">
            Our team is composed of seasoned travel enthusiasts who are dedicated to designing the perfect
            journeys for you. With a deep understanding of global cultures and destinations, we pride ourselves
            on offering a unique, personal touch to every trip.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
