import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./result.css";

const ResultsPage = () => {
  const [tours, setTours] = useState([]);
  const [selectedTour, setSelectedTour] = useState(null);
  const [guests, setGuests] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const city = queryParams.get("city");
        const maxGroupSize = queryParams.get("maxGroupSize");

        const response = await axios.get(
          "http://localhost:8800/api/tours/search/getTourBySearch",
          {
            params: { city, maxGroupSize },
          }
        );

        setTours(response.data);
      } catch (error) {
        console.error("Error fetching tours:", error);
      }
    };

    fetchTours();
  }, [location.search]);

  const handleBooking = (tour) => {
    setSelectedTour(tour);
  };

  const handleSubmitBooking = () => {
    if (!selectedTour) return;

    const formattedDate = startDate.toLocaleDateString("en-GB"); // Format date as dd/MM/yyyy

    const message = `Hello, I would like to book the trip:
- Tour: ${selectedTour.title}
- Destination: ${selectedTour.city}
- Number of Guests: ${guests}
- Date: ${formattedDate}`;

    const encodedMessage = encodeURIComponent(message);
    const whatsappURL = `https://wa.me/+918397073749?text=${encodedMessage}`;

    window.location.href = whatsappURL;
  };

  return (
    <Container className="results-container">
      <Row>
        <Col md={8}>
          <h1 className="results-title">Search Results</h1>
          <Row>
            {tours.length > 0 ? (
              tours.map((tour) => (
                <Col md={6} sm={12} key={tour._id} className="mb-4">
                  <Card className="tour-card shadow-sm">
                    <Card.Img variant="top" crossorigin="anonymous" src={`http://localhost:8800/public/images/${tour.photo}`} className="tour-image" />
                    <Card.Body>
                      <Card.Title className="tour-title">{tour.title}</Card.Title>
                      <Card.Text className="tour-desc">{tour.desc}</Card.Text>
                      <div className="tour-details">
                        {/* <p className="tour-price">
                          Price: <strong>${tour.price}</strong>
                        </p> */}
                        <p className="tour-city">City: {tour.city}</p>
                        <p className="tour-group-size">Max Group Size: {tour.maxGroupSize}</p>
                      </div>
                      <Button
                        variant="primary"
                        className="tour-btn"
                        onClick={() => handleBooking(tour)}
                      >
                        Book Now
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col md={12}>
                <p className="no-results">No tours found matching your criteria.</p>
              </Col>
            )}
          </Row>
        </Col>

        <Col md={4}>
          {selectedTour && (
            <div className="booking-form-container shadow-sm">
              <h3 className="booking-title">Book {selectedTour.title}</h3>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Number of Guests</Form.Label>
                  <Form.Control
                    type="number"
                    min="1"
                    max={selectedTour.maxGroupSize}
                    value={guests}
                    onChange={(e) => setGuests(e.target.value)}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Select Date</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="dd/MM/yyyy"
                    className="form-control"
                  />
                </Form.Group>

                <Button variant="success" type="button" onClick={handleSubmitBooking} className="w-100">
                  Confirm Booking via WhatsApp
                </Button>
              </Form>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ResultsPage;
