import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Lightroom from 'react-lightbox-gallery';
import "./gallery.css";

const Gallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get('http://localhost:8800/api/tours');
        const imagesData = response.data.map((tour) => ({
          src: `http://localhost:8800/public/images/${tour.photo}`,
          desc: tour.desc || '', // Optional: Use description from your API if available
          sub: tour.title || '' // Optional: Use title from your API if available
        }));
        setImages(imagesData);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  const settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 3
    },
    mode: "dark",
    enableZoom: false,
  };

  return (
    <div className='galleryContainer'>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Lightroom crossorigin="anonymous" images={images} settings={settings} />
    </div>
  );
}

export default Gallery;
